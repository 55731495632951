import baseLogger, { CONTEXTS } from 'widget/logger';
import createTaggedUrl from 'api/createTaggedUrl';
import HttpError from 'widget/Errors/HttpError';
import Logger from 'modules/Logger/Logger';
import { TransportInterface } from 'widget/utils/transport/types';

/*
 * Fetch station data given a station id
 */
export async function fetchTrackData({
  ampUrl = '',
  logger = baseLogger,
  liveId,
  transport,
}: {
  ampUrl?: string;
  liveId: number;
  logger?: Logger;
  transport: TransportInterface;
}): Promise<any> {
  if (!liveId) {
    const errObj = new HttpError(
      400,
      'Failed to fetch station info, station id missing',
    );
    logger.error(CONTEXTS.SERVER, errObj.message, {}, errObj);
    throw errObj;
  }

  const [url, requestName] = createTaggedUrl`${{
    ampUrl,
  }}/api/v3/live-meta/stream/${{
    trackId: liveId,
  }}/currentTrackMeta`;

  const { data } = await transport.get(url, {
    params: { defaultMetadata: true, requestName },
  });

  return data;
}
