import playbackLiveReportingService from 'widget/services/playback/liveStation/reporting';
import { getListenerId } from 'widget/LiveProfile/helpers/getProperStream';
import {
  getLiveReportingPayload,
  processMetaData,
  shouldPostMetaData,
} from 'state/Live/helpers';
import { LiveMetaData, LiveMetaState } from 'state/Live/types';
import { Meta } from 'modules/Player/types/handler';
import { TransportInterface } from 'widget/utils/transport/types';

export type ReportLiveStationPivot = (
  newData: Meta | null | undefined,
  oldMeta: LiveMetaState | undefined,
  station: {
    liveId: number;
    provider: string;
  },
) => LiveMetaState | undefined;

function liveStationPivotReporter(params: {
  ampUrl: string;
  hostName: string;
  playedFrom: number;
  profileId: string;
  sessionId: string;
  transport: TransportInterface;
}): ReportLiveStationPivot {
  const { ampUrl, hostName, playedFrom, profileId, sessionId, transport } =
    params;
  return function reportLiveStationPivot(newData, oldMeta, station) {
    const { liveId, provider = '' } = station;
    const listenerId = getListenerId() || null;

    let processedMeta;
    const newMeta = newData && (newData.metadata as LiveMetaData);
    if (newMeta) {
      let liveReportingParams;
      [processedMeta, liveReportingParams] = processMetaData(
        newMeta,
        liveId,
        listenerId,
      );

      if (
        provider.toLowerCase().includes('clear channel') &&
        shouldPostMetaData(oldMeta, processedMeta)
      ) {
        const payload = getLiveReportingPayload(
          processedMeta,
          liveReportingParams,
          {
            hostName,
            playedFrom,
            profileId,
          },
        );

        const reporter = playbackLiveReportingService({ ampUrl, transport });
        reporter(payload, {
          profileId,
          sessionId,
        });
      }
    }

    return processedMeta;
  };
}

export default liveStationPivotReporter;
