import addFit from 'widget/utils/helpers/addFit';
import BigThumbnail from 'widget/components/BigThumbnail';
import END_REASON from 'modules/Analytics/constants/endReason';
import ExternalLink from 'widget/components/ExternalLink';
import helpers from 'widget/LiveProfile/helpers';
import LeftButtonGroup from 'widget/components/LeftButtonGroup';
import mediaQueries from 'styles/mediaQueries';
import PlaybackError from 'widget/Errors/PlaybackError';
import PlayButton from 'widget/components/PlayButton';
import PlayButtonWrapper from 'widget/components/PlayButtonWrapper';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import Player from 'widget/contexts/Player';
import player from 'widget/player';
import PlayerContainer from 'widget/components/PlayerContainer';
import PlayerState, {
  PlayerErrorState,
} from 'modules/Player/constants/playerState';
import RightButtonGroup from 'widget/components/RightButtonGroup';
import ShareButton from 'widget/components/ShareButton';
import ShareCard from 'widget/components/ShareCard';
import Toolbar from 'widget/components/Toolbar';
import useModal from 'widget/components/Modal/hooks/useModal';
import useMount from 'hooks/useMount';
import useTrackers from 'widget/contexts/Trackers/hooks/useTrackers';
import withPlayerJS from 'widget/components/withPlayerJS';
import { Events } from 'widget/trackers/types';
import {
  generateLiveTrackingData,
  getLiveAnalyticsPayload,
} from 'widget/LiveProfile/helpers/analytics';
import { PlayerProps } from 'widget/LiveProfile/types';
import { SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { WIDGET_DIMENSIONS } from 'constants/widgetDimensions';

function LivePlayer({
  analytics,
  analyticsData,
  autoplay,
  countryCode,
  deviceName,
  embedUrl,
  followUrl,
  getPlaybackErrorMessage,
  imageUrl,
  isSupported,
  links,
  liveId,
  liveUrl,
  pivot,
  profileId,
  provider,
  refs,
  shareText,
  shareTitle,
  streams,
  terminalId,
}: PlayerProps) {
  const trackers = useTrackers();
  const { playLiveStation, playerState } = useContext(Player.Context);

  const isPlaying = playerState === PlayerState.Playing;
  const isBuffering = playerState === PlayerState.Buffering;
  const isLoading = playerState === PlayerState.Loading;

  const [isPlaybackError, setIsPlaybackError] = useState<boolean>(false);
  const [isUnsupported, setIsUnsupported] = useState<boolean>(false);
  const [streamIndex, setStreamIndex] = useState<number>(0);
  const [streamInitTime, setStreamInitTime] = useState<number>(0);

  const streamsRef = useRef<Array<{ type: string; url: string }>>([]);
  const isFallbackRef = useRef<boolean>(false);
  const trackingDataRef = useRef(
    generateLiveTrackingData({ ...analyticsData, liveId }),
  );
  const playedFromRef = useRef(analyticsData.playedFrom);

  const trackerData = {
    type: 'live',
    typeId: profileId,
    id: liveId,
    name: analyticsData.name,
  };

  const [ShareModal, toggleShareModal] = useModal(
    <ShareCard
      analytics={analytics}
      analyticsData={{
        id: trackerData.id,
        name: trackerData.name,
        type: trackerData.type,
      }}
      dimensions={WIDGET_DIMENSIONS.LIVE}
      followUrl={followUrl}
      shareText={shareText}
      shareTitle={shareTitle}
      url={liveUrl}
      urlWithQuery={embedUrl}
    />,
  );

  const play = (_e?: SyntheticEvent, playedFrom = playedFromRef.current) => {
    if (playedFrom !== playedFromRef.current) {
      playedFromRef.current = playedFrom;
      trackingDataRef.current = generateLiveTrackingData({
        ...analyticsData,
        liveId,
        playedFrom,
      });
    }

    if (isPlaying) {
      trackers.track(Events.Stop);
      analytics?.track(Events.Stop, trackingDataRef.current);
      analytics?.track(
        Events.StreamEnd,
        getLiveAnalyticsPayload(trackingDataRef.current, {
          endReason: END_REASON.STOP,
        }),
      );
    } else if (!isFallbackRef.current) {
      // ^^ if `isFallback` then it is still the same `play` event,
      // so don't resend analytics event or change the stream init time
      analytics?.track(Events.Play, trackingDataRef.current);
      setStreamInitTime(Date.now());
      trackers.track(Events.Play, trackerData);
    }

    if (!streamsRef.current.length) {
      streamsRef.current = helpers.getProperStream({
        countryCode,
        deviceName,
        liveId: String(liveId),
        pivot,
        playedFrom: PLAYED_FROM.RESP_WIDGET_PLAYER_PLAY,
        profileId,
        provider,
        streams,
        terminalId,
      });
    }

    /**
     * IHRWEB-16622 (add fallback handling to Live widget)
     * isFallbackRef effects in PlayerProvider:
     * false: play is toggled
     * true: new stream is loaded
     */
    if (isSupported) {
      playLiveStation(
        String(liveId),
        streamsRef.current[streamIndex],
        isFallbackRef.current,
      );
    } else {
      setIsUnsupported(true);
      setIsPlaybackError(true);
    }
  };

  /**
   * IHRWEB-14940 methods mute, setVolume, & unmute are currently only
   * externally triggered via player.js; react event parameter included for
   * methods with args for possible widget UI. analytics tbd in IHRWEB-14917
   */
  const mute = () => {
    player.mute();
    trackers.track(Events.Mute);
  };

  const setVolume = (_e: null | SyntheticEvent, volume: string | number) => {
    player.setVolume(parseInt(volume as string, 10));
  };

  const unmute = () => {
    player.unmute();
    trackers.track(Events.Unmute, { isPlaying });
  };

  if (refs) {
    /* eslint-disable no-param-reassign */
    refs.methodTypes.current = {
      ...refs.methodTypes.current,
      isLiveStream: true,
    };
    refs.mute.current = mute;
    refs.pause.current = play; // pause maps to toggling play method
    refs.play.current = play;
    refs.setVolume.current = setVolume;
    refs.unmute.current = unmute;
    /* eslint-enable no-param-reassign */
  }

  useMount(() => {
    if (autoplay) play();
  });

  useEffect(() => {
    if (playerState === PlayerState.Playing) {
      setIsPlaybackError(false);
      if (streamInitTime) {
        analytics?.track(
          Events.StreamStart,
          getLiveAnalyticsPayload(trackingDataRef.current, {
            playbackStartTime: Date.now(),
            streamInitTime,
          }),
        );
        setStreamInitTime(0);
        trackers.track(Events.StreamStart, trackerData);
      }
    }
  }, [playerState, streamInitTime]);

  useEffect(() => {
    if (
      Object.values(PlayerErrorState).includes(playerState as PlayerErrorState)
    ) {
      const stream = streamsRef.current[streamIndex] || {};
      const networkError = [
        PlayerErrorState.AccessDenied,
        PlayerErrorState.NetworkError,
      ].includes(playerState as PlayerErrorState);
      analytics?.track(
        Events.PlaybackError,
        getLiveAnalyticsPayload(trackingDataRef.current, {
          contentUrl: stream.url,
          errorMessage: getPlaybackErrorMessage(
            playerState as PlayerErrorState,
          ),
          errorType: networkError ? 'network_error' : 'playback_failure',
          streamProtocol: stream.type,
        }),
      );
      const fallbackStreamExists = streamIndex < streamsRef.current.length - 1;
      if (
        playerState === PlayerErrorState.InvalidMedia &&
        fallbackStreamExists
      ) {
        isFallbackRef.current = true;
        setStreamIndex(streamIndex + 1);
      } else {
        setIsPlaybackError(true);
      }
    }
  }, [playerState]);

  /**
   * IHRWEB-16622 (add fallback handling to Live widget)
   * streamIndex changes occur if there is an invalid media error and there are
   * un-tried streams left in the streamsRef array. if the streamIndex changes,
   * it means we want to attempt to play the new stream.
   * (however we don't want to trigger play when the index is initially set to 0)
   */
  useEffect(() => {
    if (streamIndex && isFallbackRef.current) play();
  }, [streamIndex]);

  /**
   * IHRWEB-16622 (add fallback handling to Live widget)
   * on successful play of a fallback stream, we want to send an event
   * to analytics and then turn off this behavior
   */
  useEffect(() => {
    if (playerState === PlayerState.Playing && isFallbackRef.current) {
      isFallbackRef.current = false;
      analytics?.track(
        Events.StreamFallback,
        getLiveAnalyticsPayload(trackingDataRef.current, {
          fallbackErrorCode: -1,
          fallbackErrorDescription: PlayerErrorState.InvalidMedia,
        }),
      );
    }
  }, [playerState]);

  if (isPlaybackError) {
    return (
      <PlaybackError
        error={
          isUnsupported ?
            PlayerErrorState.UnsupportedCountry
          : (playerState as PlayerErrorState)
        }
        getTheAppLink={links.getTheAppLink}
        imageUrl={imageUrl}
      />
    );
  }

  return (
    <>
      <PlayerContainer positionBottom>
        <Toolbar>
          <LeftButtonGroup>
            <PlayButtonWrapper
              data-test="live-play-button-wrapper"
              notYetInteractedWith={!isPlaying}
              supportMobileView
            >
              <PlayButton
                isLiveType
                isLoading={isBuffering || isLoading}
                isPlaying={isPlaying || isBuffering || isLoading}
                onClick={play}
              />
            </PlayButtonWrapper>
          </LeftButtonGroup>
          <RightButtonGroup>
            <ShareButton
              onClick={toggleShareModal}
              sizeToHide={mediaQueries.max.width['119']}
            />
            {imageUrl && (
              <ExternalLink data-test="thumbnail-link" href={liveUrl}>
                <BigThumbnail
                  alt={shareTitle}
                  src={addFit(imageUrl)}
                  supportMobileView
                />
              </ExternalLink>
            )}
          </RightButtonGroup>
        </Toolbar>
      </PlayerContainer>
      {ShareModal}
    </>
  );
}

export default withPlayerJS(LivePlayer);
