import mediaQueries from 'styles/mediaQueries';
import mediaQueryBuilder from 'styles/helpers/mediaQueryBuilder';
import styled from '@emotion/styled';

type Props = {
  'data-test'?: string;
};

const StaticWaveContainer = styled('div')<Props>({
  background: `url("https://www.iheart.com/static/assets/StaticWaveSvg.svg")`,
  height: '10rem',
  left: 0,
  marginTop: '-4.5rem',
  position: 'absolute',
  top: '50%',
  width: '100%',
  [mediaQueryBuilder(mediaQueries.max.height['119'])]: {
    display: 'none',
  },
});

export default StaticWaveContainer;
