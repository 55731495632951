import baseLogger, { CONTEXTS } from 'widget/logger';
import createTaggedUrl from 'api/createTaggedUrl';
import HttpError from 'widget/Errors/HttpError';
import Logger from 'modules/Logger/Logger';
import optimisticIdiotLookupWidget from 'widget/LiveProfile/helpers/optimisticIdiotLookupWidget';
import { LiveStation } from 'state/Live/types';
import { TransportInterface } from 'widget/utils/transport/types';

/*
 * Fetch live data given a live id
 */
export async function fetchLiveProfile({
  ampUrl = '',
  countryCode,
  logger = baseLogger,
  liveId,
  supportedCountries,
  transport,
}: {
  ampUrl?: string;
  countryCode: string;
  logger?: Logger;
  liveId: number;
  supportedCountries: Array<string>;
  transport: TransportInterface;
}): Promise<{ station: LiveStation; isSupported: boolean }> {
  if (!liveId) {
    const errObj = new HttpError(
      400,
      'Failed to fetch live station info, live station id missing',
    );
    logger.error(CONTEXTS.SERVER, errObj.message, {}, errObj);
    throw errObj;
  }

  const [url, requestName] = createTaggedUrl`${{
    ampUrl,
  }}/api/v2/content/liveStations/${{
    liveId: String(liveId),
  }}`;

  let data = { hits: [] };

  try {
    ({ data } = await transport.get(url, { params: { requestName } }));

    if (!data.hits.length) {
      const errObj = new HttpError(
        400,
        'live station id missing. Failed to fetch live station info, no results',
      );
      logger.error(CONTEXTS.SERVER, errObj.message, {}, errObj);
      throw errObj;
    }
  } catch (error: any) {
    const { response } = error;
    const { status } = response;
    const errObj = new Error(`Failed to fetch station info, ${status}`);
    logger.error(CONTEXTS.SERVER, errObj.message, {}, errObj);

    if (!__CLIENT__ && response.status === 404) {
      const countryCodes = supportedCountries.filter(
        code => code !== countryCode,
      );

      return optimisticIdiotLookupWidget({ countryCodes, liveId, logger });
    }
  }

  return { station: data.hits[0], isSupported: true };
}
