import createAuthHeaders from 'widget/services/utils/createAuthHeaders';
import createTaggedUrl from 'api/createTaggedUrl';
import { LiveReportingPayload } from 'state/Live/types';
import { TransportInterface } from 'widget/utils/transport/types';

type Props = {
  ampUrl: string;
  transport: TransportInterface;
};

function playbackLiveReportingService({ ampUrl, transport }: Props) {
  return async function postPlaybackLiveReporting(
    metaData: LiveReportingPayload,
    sessionInfo: {
      profileId: string;
      sessionId: string;
    },
  ): Promise<void> {
    const [url, requestName] = createTaggedUrl`${{
      ampUrl,
    }}/api/v3/playback/liveStation/reporting`;

    transport.post(url, metaData, {
      headers: createAuthHeaders(sessionInfo),
      params: { requestName },
    });
  };
}

export default playbackLiveReportingService;
