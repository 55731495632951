import * as LiveServices from 'widget/LiveProfile/services';
import baseLogger from 'widget/logger';
import HttpError from 'widget/Errors/HttpError';
import qs from 'qs';
import { getIdFromSlug } from 'state/Routing/helpers';
import { InitialProps, InstanceMethods } from 'widget/PodcastProfile/types';
import { LiveProfileResolvedProps } from 'widget/LiveProfile/types';
import { makeLiveStationPath } from 'state/Live/selectors';
import { pick } from 'lodash-es';
import { TrackingStationType } from 'widget/types/widgetTrackingConfig';
import { WIDGET_DIMENSIONS } from 'constants/widgetDimensions';
import { WidgetLocationConfig } from 'widget/types';

export default async function getInitialProps(
  { params, query }: InitialProps,
  { logger = baseLogger, transport }: InstanceMethods,
  {
    api,
    countryCode = '',
    featureFlags = { pivotGeoEnabled: false },
    links = { getTheAppLink: '' },
    supportedCountries = [],
    terminalId = 0,
    websiteUrl = '',
  }: WidgetLocationConfig,
): Promise<LiveProfileResolvedProps> {
  const { id: urlId } = params;
  const { autoplay } = query;
  const liveId = getIdFromSlug(urlId);
  const { pivotGeoEnabled } = featureFlags;

  if (!liveId) throw new HttpError(400, 'station id missing.');

  const { station, isSupported } = await LiveServices.fetchLiveProfile({
    ampUrl: api.server,
    countryCode,
    liveId,
    logger,
    supportedCountries,
    transport,
  });

  const stationData = await LiveServices.fetchTrackData({
    ampUrl: api.server,
    liveId,
    logger,
    transport,
  });

  const { callLetters, description, id, logo, name, provider, streams } =
    station;

  const slugifyiedId = makeLiveStationPath(name, id);

  // query string parameters for analytics so that we can tell the user came to the live profile from widget
  const trackParamsforLandingUrls = {
    keyid: name,
    pname: 'live_profile',
    sc: 'widget_share',
  };

  const baseLiveUrl = `${websiteUrl}${slugifyiedId}`;

  return {
    ampUrl: api.client,
    autoplay: !!autoplay,
    baseLiveUrl,
    callLetters,
    countryCode,
    dimensions: { ...WIDGET_DIMENSIONS.LIVE },
    description,
    embedUrl: `${baseLiveUrl}?${qs.stringify(pick(query, ['embed', 'theme']))}`,
    followUrl: `${baseLiveUrl}?${qs.stringify({
      ...trackParamsforLandingUrls,
      follow: true,
    })}`,
    imageUrl: logo.replace('http://', 'https://'),
    isSupported,
    links,
    liveId: id,
    liveUrl: `${baseLiveUrl}?${qs.stringify(trackParamsforLandingUrls)}`,
    logo: logo.replace('http://', 'https://'),
    name,
    pivot: pivotGeoEnabled,
    provider,
    stationData,
    stationType: TrackingStationType.CUSTOM,
    streams,
    terminalId,
  };
}
