import type { PlayerErrorState } from 'modules/Player/constants/playerState';

type PlaybackErrorProps = {
  contentUrl: string;
  errorMessage: string;
  errorType: 'network_error' | 'playback_failure';
  streamProtocol: string;
};

type StreamEndProps = {
  endReason: string;
};

type StreamFallbackProps = {
  fallbackErrorCode: number;
  fallbackErrorDescription: PlayerErrorState;
};

type StreamStartProps = {
  playbackStartTime: number;
  streamInitTime: number;
};

type TrackingData = {
  item: {
    asset: {
      id: string;
      name?: string;
      sub: { name: string };
    };
  };
  station: {
    asset: TrackingData['item']['asset'];
    playedFrom?: number;
  };
};

/**
 * Maps component props to data fields for Play/Stop events
 * Also is base payload for other analytics calls
 */
export function generateLiveTrackingData({
  callLetters,
  liveId,
  name,
  playedFrom,
}: {
  callLetters: string;
  liveId: number;
  name?: string;
  playedFrom?: number;
}): TrackingData {
  return {
    item: {
      asset: {
        id: `live|${liveId}`,
        name: callLetters,
        sub: {
          name: name ?? '',
        },
      },
    },
    station: {
      asset: {
        id: `live|${String(liveId)}`,
        name: callLetters,
        sub: {
          name: name ?? '',
        },
      },
      playedFrom,
    },
  };
}

export function getLiveAnalyticsPayload(
  trackingData: TrackingData,
  data:
    | PlaybackErrorProps
    | StreamEndProps
    | StreamFallbackProps
    | StreamStartProps,
): {
  item: TrackingData['item'];
  station: TrackingData['station'] &
    (
      | PlaybackErrorProps
      | StreamEndProps
      | StreamFallbackProps
      | StreamStartProps
    );
} {
  return {
    ...trackingData,
    station: {
      ...trackingData.station,
      ...data,
    },
  };
}
