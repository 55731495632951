import * as LiveService from 'widget/LiveProfile/services';
import Container from 'widget/components/Container';
import helpers from 'widget/LiveProfile/helpers';
import IHeartLink from 'widget/components/IHeartLink';
import LivePlayer from './LivePlayer';
import liveStationPivotReporter, {
  ReportLiveStationPivot,
} from 'widget/trackers/reportLiveStationPivot';
import MainContent from 'widget/components/MainContent';
import PLAYED_FROM from 'modules/Analytics/constants/playedFrom';
import Player from 'widget/contexts/Player';
import player from 'widget/player';
import PlayerState, {
  PlayerErrorState,
} from 'modules/Player/constants/playerState';
import ShouldShow from 'components/ShouldShow';
import StaticWaveContainer from 'widget/components/LiveMainContent/primitives/StaticWaveContainer';
import useInterval from '@use-it/interval';
import useMount from 'hooks/useMount';
import useTrackPageViewOnMount from 'widget/hooks/useTrackPageViewOnMount';
import { ComponentProps } from 'widget/LiveProfile/types';
import { getErrorMessageTranslator } from 'widget/Errors/PlaybackError/helpers/getErrorMessageTranslator';
import { LiveMetaState } from 'state/Live/types';
import { Meta } from 'modules/Player/types/handler';
import { useContext, useEffect, useState } from 'react';
import { useSession } from 'widget/utils/session';
import { useTranslate } from 'widget/hooks';

function LiveProfile({
  ampUrl,
  analytics,
  autoplay,
  callLetters,
  countryCode,
  description,
  embedUrl,
  followUrl,
  isMobile,
  isSupported,
  links,
  liveId,
  liveUrl,
  logger,
  logo,
  name,
  pivot,
  provider,
  stationData,
  streams,
  terminalId,
  transport,
}: ComponentProps) {
  const translate = useTranslate();
  const { playerState } = useContext(Player.Context);
  const {
    artist: stationArtist,
    title: stationTitle,
    imagePath: stationTrackImage,
  } = stationData;
  const [artist, setArtist] = useState(stationArtist);
  const [title, setTitle] = useState(stationTitle);
  const [trackImage, setTrackImage] = useState(stationTrackImage);
  const { profileId, sessionId } = useSession();
  const [, setMeta] = useState<LiveMetaState | undefined>(undefined);
  let pageHost = '';
  let pageURL = '';

  if (__CLIENT__ && document.referrer) {
    const { host, href } = new URL(document.referrer);
    pageHost = host;
    pageURL = href;
  }

  const checkForCurrentTrackData = async () => {
    const {
      artist: newArtist,
      title: newTitle,
      imagePath: newTrackImage,
    } = await LiveService.fetchTrackData({
      ampUrl,
      liveId,
      logger,
      transport,
    });

    setArtist(newArtist);
    setTitle(newTitle);
    setTrackImage(newTrackImage);
  };

  let reportLiveStationPivot: ReportLiveStationPivot | (() => undefined) = () =>
    undefined;

  const reportMeta = (newMeta: Meta, prevMeta: LiveMetaState | undefined) =>
    reportLiveStationPivot(newMeta, prevMeta, {
      liveId,
      provider,
    });

  useEffect(() => {
    let unsubscribe: () => void = () => null;
    if (pivot && profileId && sessionId) {
      reportLiveStationPivot = liveStationPivotReporter({
        ampUrl,
        hostName: 'webapp.US',
        playedFrom: PLAYED_FROM.RESP_WIDGET_PLAYER_PLAY,
        profileId,
        sessionId,
        transport,
      });
      unsubscribe = player.subscribe({
        meta: nextMeta => {
          setMeta(prevMeta => reportMeta(nextMeta, prevMeta));
        },
      });
    }
    return unsubscribe;
  }, [profileId, sessionId]);

  useTrackPageViewOnMount();

  useMount(() => {
    if (analytics) {
      analytics.trackPageView!({
        contentFrame: 'responsive_widget',
        id: `live|${liveId}`,
        inNetwork: pageHost.includes('iheart').toString(),
        name: callLetters,
        pageHost,
        pageName: 'live_profile',
        pageURL,
      });
    }
  });

  useInterval(() => {
    checkForCurrentTrackData();
  }, 15000);

  if (!artist && !title) checkForCurrentTrackData();

  return (
    <Container>
      <ShouldShow
        shouldShow={
          !Object.values(PlayerErrorState).includes(
            playerState as PlayerErrorState,
          )
        }
      >
        <StaticWaveContainer />
        <MainContent
          hasPlayed={[
            PlayerState.Playing,
            PlayerState.Paused,
            PlayerState.Buffering,
          ].includes(playerState as PlayerState)}
          icons={[<IHeartLink href={liveUrl} key="iheartlink" />]}
          infoHeading={`${name} - ${description}`}
          infoHeadingSmallHeight={artist ? `${artist} •` : ''}
          subHeading={artist}
          title={title}
        />
      </ShouldShow>
      <LivePlayer
        analytics={analytics}
        analyticsData={{
          name,
          playedFrom: PLAYED_FROM.RESP_WIDGET_PLAYER_PLAY,
          callLetters,
        }}
        autoplay={autoplay}
        countryCode={countryCode}
        description={description}
        deviceName={isMobile ? 'web-mobile' : 'web-desktop'}
        embedUrl={embedUrl}
        followUrl={followUrl}
        getPlaybackErrorMessage={getErrorMessageTranslator(translate)}
        imageUrl={logo}
        isSupported={isSupported}
        links={links}
        liveId={liveId}
        liveUrl={liveUrl}
        metadata={{
          artist,
          mainImage: logo,
          title,
          trackImage,
        }}
        pivot={pivot}
        profileId={profileId}
        provider={provider}
        shareText={translate('Share this live station')}
        shareTitle={name}
        streams={streams}
        terminalId={terminalId}
      />
    </Container>
  );
}

LiveProfile.getInitialProps = helpers.getInitialProps;

export default LiveProfile;
