import cookie from 'js-cookie';
import qs from 'qs';
import {
  addZipToOwnedAndOperatedStreams,
  createStreamList,
} from 'state/Live/helpers/streams';
import { PlayerProps } from '../types';
import { Streams } from 'state/Live/types';

export function getListenerId() {
  return (
    window.com_adswizz_synchro_getListenerId &&
    window.com_adswizz_synchro_getListenerId()
  );
}

function getProperStream({
  countryCode,
  deviceName,
  streams,
  liveId,
  pivot,
  playedFrom,
  profileId,
  provider,
  terminalId,
}: {
  countryCode: string;
  deviceName: PlayerProps['deviceName'];
  streams: Streams;
  liveId: string;
  pivot?: boolean;
  playedFrom: number;
  profileId: string | undefined;
  provider: string;
  terminalId: number;
}) {
  const zip = cookie.get('geoZipcode');
  const isOwnedAndOperated = provider.toLowerCase().includes('clear channel');

  const streamsCopy = addZipToOwnedAndOperatedStreams(
    streams,
    liveId,
    zip,
    pivot,
    isOwnedAndOperated,
  );

  const streamList = createStreamList(streamsCopy);

  const trackingData = {
    'aw_0_1st.playerid': 'iHeartRadioWebPlayer',
    'aw_0_1st.skey': profileId,
    clientType: 'web',
    companionAds: false,
    deviceName,
    dist: 'iheart',
    host: `webapp.${countryCode || 'US'}`,
    listenerId: getListenerId() || null,
    playedFrom,
    pname: 'live_profile',
    profileId,
    stationid: liveId,
    terminalId,
    territory: countryCode,
  };

  return streamList.map((stream: { type: string; url: string }) => {
    const properQuery = stream.url.includes('?') ? '&' : '?';
    return {
      type: stream.type,
      url: stream.url.concat(properQuery + qs.stringify(trackingData)),
    };
  });
}
export default getProperStream;
